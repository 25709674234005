<template>
    <div>
		<br/>
        <div class="grid">
			<div class="griditem" @click="clickFunction">
				<div class="gridheading"><b>Panchang</b></div>
				<div>
					<span>Find panchang for your selected place and date</span>
				</div>
            </div>
            <div class="griditem">
				<div class="gridheading"><b>Muhurtha</b></div>
				<div>
					<span>Find out auspicious muhurtha (times) for your rashi (moon sign) and nakshatra (constallation) on a selected date and place.</span>
				</div>
            </div>
            <div class="griditem">
				<div class="gridheading"><b>Marriag Matching</b></div>
				<div>
					<span>Find out marriage compatibility btween a boy and girl with their rashi (moon sign) and nakshatra (constellation).</span>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
// import Slider from './Slider.vue';

export default {
    data(){
        return {
		}
    },
    components:{
        // Slider,
    }
}

</script>

<style scoped>
.panchanggraph{
	color: darkgrey;
}
.grid {
	/* Define the element as a grid container */
	display: grid;
	/* Auto-fit as many items on a row as possible without going under 180px */
	grid-template-columns: repeat(2, 1fr);
	/* A little spacing between articles */
	grid-gap: 1em;
	/* margin-bottom: .5em;
	margin-left: .5em;
	margin-right: .5em; */
	/* background: #2B2B2B; */
	background: #111826;
	/* background-image: var(--bg-gradient-two); */
	border-radius: 5px;
	/* padding-top: 8px;
	padding-bottom: 5px;
	padding-left: 5px;
	padding-right: 5px; */
	/* color: white; */
}

.griditem{
	white-space: initial;
	border-radius: 5px;
	background: #1c2940;
	margin-top: 2px;
}
.griditem:hover {
	cursor: pointer;
}
.griditem span{
	text-align: left;
	white-space: initial;
	color:darkgrey;
	font-family: arial;
	font-size: 12px;
}
.gridheading{
	background-image: linear-gradient(0deg,rgba(0,0,0,.1),transparent 76%),linear-gradient(
90deg,#3B4755 0,#3B4755 74px, #3B4755 0, #3B4755);
	margin: 0;
	padding: 2px;
	border-radius: 3px;
}
.griditemheading{
	background-image: linear-gradient(0deg,rgba(0,0,0,.1),transparent 76%),linear-gradient(
90deg,#3B4755 0,#3B4755 74px, #3B4755 0, #3B4755);
	padding: 2px;
	margin: 3px;
	border-radius: 3px;
}

/* Single column display for phones */
@media (max-width: 459px) {
	.grid {
		display: flex;
		flex-direction: column;
	}
} 

</style>